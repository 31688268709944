export default {
  setItem(key, value, storage = window.sessionStorage) {
    storage.setItem(key, JSON.stringify(value));
  },
  getItem(key, storage = window.sessionStorage) {
    return JSON.parse(storage.getItem(key));
  },
  removeItem(key, storage = window.sessionStorage) {
    return storage.removeItem(key);
  },
  getAccessToken() {
    return this.getItem('access_token');
  },
  getToken() {
    return {
      accessToken: this.getItem('access_token'),
      refreshToken: this.getItem('refresh_token'),
    };
  },
  setToken(data) {
    this.setItem('access_token', data.accessToken);
    this.setItem('refresh_token', data.refreshToken);
  },
  removeToken() {
    this.removeItem('access_token');
    this.removeItem('refresh_token');
  },
  localGet(data) {
    return localStorage.getItem(data);
  },
  localSet(key, data) {
    localStorage.setItem(key, data);
  },
  localRemove(key) {
    localStorage.removeItem(key);
  },
  localClear() {
    localStorage.clear();
  },
};
