export default {
  namespaced: true,

  state: {
    user: null,
    cert: null,
    appCallBackUrl: null,
    appId: null,
  },

  getters: {
    getUser: (state) => state.user,
    getCert: (state) => state.cert,
    getAppCallBackUrl: (state) => state.appCallBackUrl,
    getAppId: (state) => state.appId,
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_CERT(state, cert) {
      state.cert = cert;
    },
    SET_APP_CALL_BACK_URL(state, appCallBackUrl) {
      state.appCallBackUrl = appCallBackUrl;
    },
    SET_APP_ID(state, appId) {
      state.appId = appId;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    setCert({ commit }, cert) {
      commit('SET_CERT', cert);
    },
    setAppCallBackUrl({ commit }, appCallBackUrl) {
      commit('SET_APP_CALL_BACK_URL', appCallBackUrl);
    },
    setAppId({ commit }, appId) {
      commit('SET_APP_ID', appId);
    },
  },
};
