<template>
  <VueToggles
    :value="enabled"
    height="26"
    width="48"
    checkedBg="#1490ef"
    uncheckedBg="#d5dbe3"
    @click="toggleClickHandler"
  />
</template>

<script>
import VueToggles from 'vue-toggles';

export default {
  name: 'mccToggles',
  components: { VueToggles },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    enabled: {
      get() {
        return this.value;
      },
    },
  },
  watch: {},
  created() {},
  methods: {
    toggleClickHandler(enabled) {
      if (this.$emit('click')._events.click) {
        this.$emit('click', !enabled);
      } else {
        this.$emit('input', !enabled);
      }
    },
  },
};
</script>
