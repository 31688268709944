<template>
  <div class="header">
    <div class="header-wrap">
      <template v-if="isBtnAll">
        <button
          v-if="!isClose"
          type="button"
          class="btn btn-back"
          @click="backKeyClickHandler"
        >
          <span class="blind">뒤로가기</span>
        </button>
        <span v-if="title" class="tit16">{{ title }}</span>
        <a v-if="isMenu && !isClose" class="btn btn-menu" @click="appMenuBridge">
          <span class="blind">메뉴</span>
        </a>
        <a v-if="isClose" class="btn btn-close" @click="$parent?.slidePanelClose()">
          <span class="blind">닫기</span>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerView',
  props: {
    title: {
      type: String,
      default: '내 문서함',
    },
    mode: {
      type: String,
      default: 'slidePanel',
    },
    isMenu: {
      type: Boolean,
      default: true,
    },
    isClose: {
      type: Boolean,
      default: false,
    },
    isBtnAll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    // 뒤로가기 Key 클릭시
    backKeyClickHandler() {
      if (this.mode === 'slidePanel') {
        this.$parent?.slidePanelClose();
      } else {
        this.appWonderBridge();
      }
    },
  },
};
</script>

<style scoped></style>
